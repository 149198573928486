export class RdvCardsController {
    constructor()
    {
        this.getFirstRdvAlax();
        this.getDatardvHandler();
    }

    getFirstRdvAlax()
    {
        const cards       = document.querySelector(".cards");
        const now   = new Date();
        now.setHours(0,0,0,0);
        const timeout     = Pointvision.variables.timeout;

        if (cards) {
            let urlApi;
            const env              = cards.getAttribute('data-id-alax');
            const codePartner      = cards.getAttribute('data-code-partner');
            const idlieu           = cards.getAttribute('data-id-lieux');

            var card               = document.querySelectorAll(".card");
            var cardMobile         = document.querySelectorAll(".card-mobile");
            var cardMobileChildren = document.querySelectorAll(".card-child-motif-inner");
            var motisIdArray = [];

            card.forEach(item => {
                motisIdArray.push(item.getAttribute('data-id-alaxione'));
            });

            motisIdArray = motisIdArray.sort();
            let motifsArrId = [];
            let motifsId = '';

            for (var i = 0; i < motisIdArray.length; i++) {
                if (motisIdArray[i] !== motisIdArray[i-1]) {
                    motifsArrId.push(motisIdArray[i]);
                }
            }

            for (var i = 0; i < motifsArrId.length; i++) {
                motifsId += motifsArrId[i];
                if (i < motifsArrId.length -1) {
                    motifsId += '-';
                }
            }

            if (codePartner) {
                urlApi = env + "/ajax/renvoi_premier_dispo_lieu.php?id_lieu=" + idlieu + "&id_partenaire=" + codePartner + "&prov=web&id_motif=" + motifsId;
            } else {
                urlApi = env+"/ajax/renvoi_premier_dispo_lieu.php?id_lieu=" + idlieu + "&prov=web&id_motif=" + motifsId;
            }
            
            (async () => {
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), timeout);

                try {
                    const response = await fetch(urlApi, {
                        signal: controller.signal
                    }).then((res) => res.json())
                      .then(function (data) {
                            if (data.length !== 0) {
                                //Desktop Cards
                                Array.from(card).forEach( item => {
                                    var idAlaxioneCard = item.getAttribute('data-id-alaxione');
                                    var dateNextRdv    = item.querySelector('.card-motif-date-next-rdv');
                                    var ctaRdvLink     = item.querySelector('.card-motif-take-rdv-link-inner');
                                    if (ctaRdvLink) {
                                        var rdvHref    = ctaRdvLink.getAttribute('data-src');
                                    }

                                    Array.from(data).forEach( motif => {
                                        if (dateNextRdv) {
                                            dateNextRdv.innerHTML = "Aucun RDV disponible";
                                            if ( !ctaRdvLink.classList.contains('custom-link') ) {
                                                ctaRdvLink.classList.add("disabled");
                                            }
                                        }
                                    });

                                    Array.from(data).forEach( motif => {
                                        if (item.classList.contains("has-children")) {
                                            var children = item.querySelectorAll('.child-motif');
                                            var dateArr = [];
                                            var dateAff = [];
                                            var i;

                                            Array.from(children).forEach( child => {
                                                var idAlaxioneChildCard = child.getAttribute('data-id-alaxione');
                                                Array.from(data).forEach( childMotif => {
                                                    let datimeChildMotif = new Date(childMotif.date);
                                                    datimeChildMotif.setHours(0,0,0,0);
                                                    var idChildRdv = childMotif.id_motif;
                                                    if (idChildRdv === idAlaxioneChildCard) {
                                                        if (now <= datimeChildMotif) {
                                                            dateArr.push(childMotif.date);
                                                            dateAff.push(childMotif.date_aff);
                                                        }
                                                    }
                                                });
                                            });

                                            var smallestDate = new Date(dateArr[0]);
                                            smallestDate.setHours(0,0,0,0);
                                            var smallestDateAff = dateAff[0];

                                            if (dateArr.length !== 0) {
                                                for (i=1; i < dateArr.length; i++) {
                                                    dateArr[i] = new Date(dateArr[i]);
                                                    dateArr[i].setHours(0,0,0,0);
                                                    if (dateArr[i] < smallestDate) {
                                                        smallestDate = dateArr[i];
                                                        smallestDateAff = dateAff[i];
                                                    }
                                                }
                                                dateNextRdv.innerHTML = smallestDateAff;
                                            }
                                            else {
                                                if (dateNextRdv) {
                                                    dateNextRdv.innerHTML = "Aucun RDV disponible";
                                                }
                                            }
                                        }
                                        else {
                                            var idRdv = motif.id_motif;
                                            if ( idRdv === idAlaxioneCard ) {
                                                let datimeMotif = new Date(motif.date);
                                                datimeMotif.setHours(0, 0, 0, 0);
                                                if (now <= datimeMotif) {
                                                    dateNextRdv.innerHTML = motif.date_aff;
                                                    if ( !ctaRdvLink.classList.contains('custom-link') ) {
                                                        ctaRdvLink.classList.remove("disabled");
                                                        ctaRdvLink.setAttribute('href', rdvHref);
                                                        ctaRdvLink.href = ctaRdvLink.href.replace("{{reason}}", idRdv);
                                                    }
                                                } else {
                                                    if ( !ctaRdvLink.classList.contains('custom-link') ) {
                                                        dateNextRdv.innerHTML = "Aucun RDV disponible";
                                                        ctaRdvLink.classList.add("disabled");
                                                        ctaRdvLink.removeAttribute('href');
                                                    }
                                                }
                                            }
                                        }
                                    });
                                });

                                //Mobile Cards
                                Array.from(cardMobile).forEach(item =>{
                                    var idAlaxioneCard   = item.getAttribute('data-id-alaxione');
                                    var labelDateNextRdv = item.querySelector('.card-motif-label-next-rdv');
                                    var dateNextRdv      = item.querySelector('.card-motif-date-next-rdv');
                                    var ctaRdvLink       = item.querySelector('.card-motif-take-rdv-link-inner');
                                    if (ctaRdvLink) {
                                        if ( !ctaRdvLink.classList.contains('custom-link') ) {
                                            var rdvHref  = ctaRdvLink.getAttribute('data-src');
                                        }
                                    }

                                    if ( !item.classList.contains("has-children") ) {
                                        Array.from(data).forEach( motif => {
                                            if ( dateNextRdv ) {
                                                dateNextRdv.innerHTML = "Aucun RDV disponible";
                                                labelDateNextRdv.classList.add("d-none");
                                                if ( !ctaRdvLink.classList.contains('custom-link') ) {
                                                    ctaRdvLink.classList.add("disabled");
                                                }
                                            }
                                        });

                                        Array.from(data).forEach( motif => {
                                            var idRdv             = motif.id_motif;
                                            var datimeMotif = new Date(motif.date);
                                            datimeMotif.setHours(0,0,0,0);
                                            if ( idRdv === idAlaxioneCard ) {
                                                if ( now <= datimeMotif ) {
                                                    if ( dateNextRdv ) {
                                                        dateNextRdv.innerHTML = motif.date_aff;
                                                        if ( !ctaRdvLink.classList.contains('custom-link') ) {
                                                            labelDateNextRdv.classList.remove("d-none");
                                                            ctaRdvLink.classList.remove("disabled");
                                                            ctaRdvLink.setAttribute('href', rdvHref);
                                                            ctaRdvLink.href = ctaRdvLink.href.replace("{{reason}}", idRdv);
                                                        }
                                                    }
                                                } else {
                                                    if ( dateNextRdv ) {
                                                        dateNextRdv.innerHTML = "Aucun RDV disponible";
                                                        if ( !ctaRdvLink.classList.contains('custom-link') ) {
                                                            labelDateNextRdv.classList.add("d-none");
                                                            ctaRdvLink.classList.add("disabled");
                                                            ctaRdvLink.removeAttribute('href');
                                                        }
                                                    }
                                                }
                                                return false;
                                            }
                                        });
                                    }
                                    else {
                                        var children = item.nextElementSibling.querySelectorAll('.card-child-motif-inner');
                                        var dateArr = [];
                                        var dateAff = [];
                                        var i;

                                        Array.from(children).forEach( child => {
                                            var idAlaxioneChildCard = child.getAttribute('data-id-alaxione');
                                            Array.from(data).forEach( childMotif => {
                                                let datimeChildMotif = new Date(childMotif.date);
                                                datimeChildMotif.setHours(0,0,0,0);
                                                var idChildRdv = childMotif.id_motif;
                                                if ( idChildRdv === idAlaxioneChildCard ) {
                                                    if (now <= datimeChildMotif) {
                                                        dateArr.push(childMotif.date);
                                                        dateAff.push(childMotif.date_aff);
                                                    }
                                                }
                                            });
                                        });

                                        var smallestDate = new Date(dateArr[0]);
                                        smallestDate.setHours(0,0,0,0);
                                        var smallestDateAff = dateAff[0];

                                        if ( dateArr.length !== 0 ) {
                                            for ( i=1; i < dateArr.length; i++ ) {
                                                dateArr[i] = new Date(dateArr[i]);
                                                dateArr[i].setHours(0,0,0,0);
                                                if ( dateArr[i] < smallestDate ) {
                                                    smallestDate    = dateArr[i];
                                                    smallestDateAff = dateAff[i];
                                                }
                                            }
                                            if (dateNextRdv) {
                                                dateNextRdv.innerHTML = smallestDateAff;
                                            }
                                        }
                                        else {
                                            if (dateNextRdv) {
                                                dateNextRdv.innerHTML = "Aucun RDV disponible";
                                                labelDateNextRdv.classList.add("d-none");
                                            }
                                        }
                                    }
                                });

                                //Mobile Cards children
                                Array.from(cardMobileChildren).forEach( item => {
                                    var labelDateNextRdv = item.querySelector('.card-motif-label-next-rdv');
                                    var dateNextRdv      = item.querySelector('.card-motif-date-next-rdv');
                                    var idAlaxioneCard   = item.getAttribute('data-id-alaxione');
                                    var ctaRdvLink       = item.querySelector('.card-motif-take-rdv-link-inner');
                                    if (ctaRdvLink) {
                                        var rdvHref      = ctaRdvLink.getAttribute('data-src');
                                    }

                                    Array.from(data).forEach( motif => {
                                        if (ctaRdvLink) {
                                            ctaRdvLink.classList.add("disabled");
                                        }
                                    });

                                    Array.from(data).forEach( motif => {
                                        if (ctaRdvLink) {
                                            labelDateNextRdv.classList.add("d-none");
                                            ctaRdvLink.classList.add("disabled");
                                            ctaRdvLink.removeAttribute('href');
                                        }
                                    });

                                    Array.from(data).forEach( motif => {
                                        var idRdv             = motif.id_motif;
                                        var datimeMotif = new Date(motif.date);
                                        datimeMotif.setHours(0,0,0,0);
                                        if ( idRdv === idAlaxioneCard ) {
                                            if ( now <= datimeMotif ) {
                                                dateNextRdv.innerHTML = motif.date_aff;
                                                if (ctaRdvLink) {
                                                    labelDateNextRdv.classList.remove("d-none");
                                                    ctaRdvLink.classList.remove("disabled");
                                                    ctaRdvLink.setAttribute('href', rdvHref);
                                                    ctaRdvLink.href = ctaRdvLink.href.replace("{{reason}}", idRdv);
                                                }
                                            } else {
                                                dateNextRdv.innerHTML = "Aucun RDV disponible";
                                                if (ctaRdvLink) {
                                                    ctaRdvLink.classList.add("disabled");
                                                    ctaRdvLink.removeAttribute('href');
                                                }
                                            }
                                            return false;
                                        }
                                    });
                                });
                            }
                            else {
                                //Desktop Cards
                                Array.from(card).forEach(item =>{
                                    var dateNextRdv = item.querySelector('.card-motif-date-next-rdv');
                                    var ctaRdvLink  = item.querySelector('.card-motif-take-rdv-link-inner');

                                    dateNextRdv.innerHTML = "Aucun RDV disponible";
                                    ctaRdvLink.classList.add("disabled");
                                    ctaRdvLink.removeAttribute('href');
                                });

                                //Mobile Cards
                                Array.from(cardMobile).forEach(item => {
                                    var labelDateNextRdv = item.querySelector('.card-motif-label-next-rdv');
                                    var dateNextRdv      = item.querySelector('.card-motif-date-next-rdv');
                                    var ctaRdvLink       = item.querySelector('.card-motif-take-rdv-link-inner');

                                    dateNextRdv.innerHTML = "Aucun RDV disponible";
                                    labelDateNextRdv.classList.add("d-none");
                                    if (ctaRdvLink) {
                                        ctaRdvLink.classList.add("disabled");
                                        ctaRdvLink.removeAttribute('href');
                                    }
                                });

                                //Mobile Cards children
                                Array.from(cardMobileChildren).forEach(item => {
                                    var labelDateNextRdv = item.querySelector('.card-motif-label-next-rdv');
                                    var dateNextRdv      = item.querySelector('.card-motif-date-next-rdv');
                                    var ctaRdvLink       = item.querySelector('.card-motif-take-rdv-link-inner');

                                    dateNextRdv.innerHTML = "Aucun RDV disponible";
                                    labelDateNextRdv.classList.add("d-none");
                                    ctaRdvLink.classList.add("disabled");
                                    ctaRdvLink.removeAttribute('href');
                                });
                            }
                      })
                      .catch(function (error) {
                        console.log(error);

                        Array.from(card).forEach(item =>{
                            var dateNextRdv = item.querySelector('.card-motif-date-next-rdv');
                            var ctaRdvLink  = item.querySelector('.card-motif-take-rdv-link-inner');

                            dateNextRdv.innerHTML = "Aucun RDV disponible";
                            ctaRdvLink.classList.add("disabled");
                            ctaRdvLink.removeAttribute('href');
                        });

                        //Mobile Cards
                        Array.from(cardMobile).forEach(item =>{
                            var labelDateNextRdv = item.querySelector('.card-motif-label-next-rdv');
                            var dateNextRdv      = item.querySelector('.card-motif-date-next-rdv');
                            var ctaRdvLink       = item.querySelector('.card-motif-take-rdv-link-inner');

                            dateNextRdv.innerHTML = "Aucun RDV disponible";
                            labelDateNextRdv.classList.add("d-none");
                            if (ctaRdvLink) {
                                ctaRdvLink.classList.add("disabled");
                                ctaRdvLink.removeAttribute('href');
                            }
                        });

                        //Mobile Cards children
                        Array.from(cardMobileChildren).forEach(item =>{
                          var labelDateNextRdv = item.querySelector('.card-motif-label-next-rdv');
                          var dateNextRdv      = item.querySelector('.card-motif-date-next-rdv');
                          var ctaRdvLink       = item.querySelector('.card-motif-take-rdv-link-inner');

                          dateNextRdv.innerHTML = "Aucun RDV disponible";
                          labelDateNextRdv.classList.add("d-none");
                          ctaRdvLink.classList.add("disabled");
                          ctaRdvLink.removeAttribute('href');
                        });
                      });
                      //console.log(response);
                } catch (error) {
                    console.error(error);
                } finally {
                    clearTimeout(timeoutId);
                }
            })();
        }
    }

    getDatardvHandler() {
        let btnRdvs          = document.querySelectorAll('.card-motif-take-rdv-link-inner');
        let currentUrl= window.location.href;
        let url      = currentUrl.split('/');
        let centerName=  url[3];

        let idMotif;
        let labelMotif;

        Array.from(btnRdvs).forEach(btnRdv => {
            btnRdv.addEventListener('click', function (e) {
                if (!btnRdv.classList.contains('disabled')) {
                    /*if (window.innerWidth < 1024) {
                        idMotif    = btnRdv.parentElement.getAttribute('data-id-alaxione');
                        labelMotif = btnRdv.querySelector('.card-motif-title').innerHTML;
                    }
                    else {
                        idMotif    = btnRdv.parentElement.parentElement.getAttribute('data-id-alaxione');
                        labelMotif = btnRdv.parentElement.parentElement.querySelector('.card-motif-title').innerHTML;
                    }

                    dataLayer.push({
                        'event': 'clic_btn_priserdv',
                        'centre_pv': centerName,
                        'reason_code': idMotif,
                        'reason_lbl': labelMotif,
                    });*/
                    
                    if (typeof fbq !== "undefined"){
                        fbq('track', 'ViewContent');
                    }
                }
            });
        });
    }
}
